* { box-sizing: border-box; }

.shift-cards { 
  display: flex; 
  justify-content: space-between;
}

.shift-card {
    box-shadow: 0 0 4px rgba(0,0,0,0.4);
    background-color: white;
    padding: 1em;
    display: flex;
    flex-grow: 1;
    margin-bottom: 2em;	
    white-space: nowrap;

}

.shfit-card1 {
    background-color: white;
    width: 100%;
    white-space: nowrap;
}

.shift-card4 {
    border: 1px solid;
    display: inline;
    margin: 0 10px;
    width: 100%;
    white-space: nowrap;
  }
  
  
  .shift-card:hover,
  .shift-card:focus,
  .shift-card:active {
    border-color: #0070f3;
  }
  
  .shift-card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
  }
  
  .shift-card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  
  .box {
    float: left;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .2);
  }

  /* Solid border */
  hr.solid {
    border-top: 3px solid #bbb;
  }
  /* Rounded border */
  hr.rounded {
    border-top: 8px solid #bbb;
    border-radius: 5px;
  } 
