.columns {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }

  .column {
    flex: 50%;
    width: 100%;
  }

  .basic-swipeable-list__item-content-left {
    background-color: green;
    box-sizing: border-box;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 8px;
  }
  
  .basic-swipeable-list__item-content-right {
    background-color: red;
    box-sizing: border-box;
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    justify-content: flex-end;
  }

  .center-search {
    margin: auto;
    width:auto;
    padding: 10px;
    text-align: center;
    background-color: white;
  }