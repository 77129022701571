
.COLORS_1
  $yellow: #F6E27F
  $green: #4E937A
  $blue: #4281A4
  $grey: #6A7062
  $dark: #0E3B43
  $timeEntry: $yellow
  $shift: $blue

$yellow: #EDD382
$green: #06A77D
$blue: #006494
$grey: #6A7062
$lightDark: lighten($grey, 50%)
$dark: #262626
$primary: #083D77
$timeEntry: $yellow
$shift: $blue
$red: red
$orange: #f36d33
$brown: brown
$amber: #EEB313

$color-main: #F1F2F6
$color-primary: #DADEE1
$color-secondary: #ADB2B8
$color-submain: #8A929B


.StatusIcons2
  display: flex
  position: relative
  width: 100%
  flex-direction: row
  justify-content: space-around
  align-items: center
  // align-content: stretch
  // flex-basis: auto
  // flex-wrap: nowrap
  .statusIcon2
    display: block
    flex: 0

.StatusIcons
  display: flex
  position: relative
  width: 100%
  flex-direction: row
  //padding: 0.5em 0.5em 0.5em 0.5em
  justify-content: space-around
  align-items: center
  // align-content: stretch
  // flex-basis: auto
  // flex-wrap: nowrap
  .statusIcon
    display: block
    flex: 0
    justify-content: space-around
    align-items: center
    color: $color-secondary
    opacity: 1
    .stat
      color: $color-secondary
      opacity: 1
      vertical-align: middle
      text-align: center
      font-size: 62px
      border-radius: 50%
      padding: 10px
      /* background-color: green;*/
      background: linear-gradient(to bottom right, #e5e8ec, #d2d1d4)
      color: white
      display: inline-block
      height: 10px
      width: 10px
      //-border-radius: 60%
      //display: inline-block
      //-box-shadow: 0 0 2px #888
      //-padding: 0.5em 0.6em
      &.on
        background: $dark
      &.ot
         background: $dark
      &.pto
        background: $orange
      &.available
        background: $dark
      &.bidded
        background: $green
      &.sick
        background: $brown
      &.jde
        color: white
        background: $amber
      &.jde.synced
        background: $green
      &.approved
        background: #4dacff
      &.rejected
        background: $red
      &.submitted-notes
        background: $red
      &.submitted
        background: $blue
      &.processed
        background: $green
      &.collision
        background: $red
      &.acting
        background: #2E8B57
      &.payPeriodLock
        background: #d6350d
      &.shiftLock
        background: #d6350d
      &.timeEntryLock
        background: #d6350d
      &.payPeriodUnlock
        background: #2E8B57
      &.shiftUnlock
        background: #2E8B57
      &.timeEntryUnlock
        background: #2E8B57
      &.locked
        background: #d6350d
      &.unlocked
        background: #2E8B57
    .stat::before 
      font-size: 17px  // * change this as you need */
    .on
      svg
        color: white
        fill: white

  



